@import "../node_modules/@syncfusion/ej2/material.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  margin-top: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stripe_submit {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  font-size: large;
  font-weight: bold;
  background-color: var(--primary-color);
  color: white;
  border: 0;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  border-radius: 5px;
}

.stripe_submit:disabled {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  font-size: large;
  font-weight: bold;
  background-color: grey;
  color: white;
  border: 0;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  border-radius: 5px;
}

.stripe_submit:hover {
	cursor: pointer;
	background: var(--primary-hover-color);
}

