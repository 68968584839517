*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}

.register-page-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-container{
  width: 568px;
  padding: 10px 25px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.281);
}

.register-dob {
  display: flex;
  flex-direction: row;
}

.register-buttons {
  display: flex;
  margin-top: 20px;
}

@media only screen and (max-width: 568px) {
  .register-page-container {
    height: auto;
  }

  .register-container{
    width: 100vw;
    height: auto;
    border-radius: 5px;
  }
}

.register-cancel-btn {
	background: var(--primary-color);
	color: white;
	padding: 8px 5px;
	width: 150px;
  margin: auto;
	border-color: var(--primary-color);
	border-radius: 5px;
	font-size: 18px;
	border-style: solid;
	transition: 0.3s;
}

.register-cancel-btn:hover {
	color: white;
	background: var(--primary-hover-color);
	border-color: var(--primary-hover-color);
  cursor: pointer;
}