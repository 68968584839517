*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
	background: #ececec;
}

.sign-in-help-text {
    margin-top: 20px;
}

.dp-link {
  width: 50%;
  position: absolute;
  bottom: 0px;
  text-align: center;
}

.login-email {
  color: black;
  width: 200px;
  max-width: 200px;
  padding: 12px 15px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid black;
  background: white;
  outline: none;
}

.login-dob {
  color: black;
  width: 200px;
  max-width: 200px;
  padding: 12px 15px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid black;
  background: white;
  outline: none;
}

.login-signin-btn {
	background: var(--primary-color);
	color: white;
	padding: 5px 5px;
	width: 200px;
	border-radius: 5px;
	margin-top: 20px;
  margin-bottom: 20px;
	font-size: 20px;
	border-style: solid;
  border-color: var(--primary-color);
	transition: 0.3s;
}

.login-signin-btn:hover {
	color: white;
  border-color: var(--primary-hover-color);
	background: var(--primary-hover-color);
  cursor: pointer;
}

.login-signup-btn {
	background: transparent;
	color: white;
	border-color: white;
	padding: 10px 5px;
	width: 200px;
	border-radius: 5px;
	margin-top: 40px;
	font-size: 20px;
	border-style: solid;
	transition: 0.3s;
}

.login-signup-btn:hover {
	background: var(--primary-hover-color);
	border-color: var(--primary-hover-color);
  cursor: pointer;
}

.signup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 768px;
  height: 460px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.281);
  overflow: hidden;
  display: flex;
  text-align: center;
}

@media only screen and (max-width: 768px) {
    .signup {
        max-width: 700px;
      }
  }

  @media only screen and (max-width: 712px) {
    .signup {
        max-width: 650px;
      }
  }

.signup-leftside {
    width: 50%;
    padding: 30px 35px;
}

.signin-rightside {
  width: 50%;
  padding: 30px 35px;
}

.signup-leftside {
  background: var(--secondary-color);
  color: white;
}

.e-responsive-dialog {
    box-shadow: none;
    border: 1px solid #dddddd;
}

/* Render the mobile pager by default */
@media (max-width: 3840px) {
    .e-adaptive-demo .e-pager {
        padding: 13px 0;
    }
    .e-adaptive-demo .e-pager div.e-parentmsgbar {
        box-sizing: border-box;
        display: inline-block;
        float: initial;
        padding-bottom: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        vertical-align: top;
        width: calc(60% - 48px);
    }
    .e-adaptive-demo .e-pager .e-pagesizes {
        display: none;
    }
    .e-adaptive-demo .e-pager .e-pagecountmsg {
        display: none;
    }
    .e-adaptive-demo .e-pager .e-pagercontainer {
        display: none;
    }
    .e-adaptive-demo .e-pager .e-icons {
        font-size: 11px;
    }
    .e-adaptive-demo .e-pager .e-mfirst,
    .e-adaptive-demo .e-pager .e-mprev,
    .e-adaptive-demo .e-pager .e-mnext,
    .e-adaptive-demo .e-pager .e-mlast {
        border: 0;
        box-sizing: border-box;
        display: inline-block;
        padding: 1% 5%;
    }
    .e-adaptive-demo .e-pager .e-mfirst {
        margin-right: 4px;
        text-align: right;
        width: calc(10% + 11px);
    }
    .e-adaptive-demo .e-pager .e-mprev {
        margin: 0 4px;
        text-align: right;
        width: 10%;
    }
    .e-adaptive-demo .e-pager .e-mnext {
        margin: 0 4px;
        text-align: left;
        width: 10%;
    }
    .e-adaptive-demo .e-pager .e-mlast {
        margin-left: 4px;
        text-align: left;
        width: calc(10% + 11px);
    }
    .e-adaptive-demo .e-bigger .e-pager,
    .e-adaptive-demo .e-pager.e-bigger {
        padding: 19px 0;
    }
    .e-adaptive-demo .e-bigger .e-pager.e-rtl div.e-parentmsgbar,
    .e-adaptive-demo .e-pager.e-bigger.e-rtl div.e-parentmsgbar {
        margin-right: 0;
    }
    .e-adaptive-demo .e-bigger .e-pager div.e-parentmsgbar,
    .e-adaptive-demo .e-pager.e-bigger div.e-parentmsgbar {
        padding: 0;
    }
}

@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,900|Poppins:400,500');

	.mobile-login-form {
	  font-family: 'Poppins', sans-serif;
	  height: 100vh;
	  display: flex;
	  background-color: #fff;
	  width: 450px;
    margin: 0 auto;
	  border-radius: 5px;
	  padding: 1em;
	  position: relative;
	  overflow: hidden;
	  box-shadow: 0 6px 31px -2px rgba(0, 0, 0, .3);
	}

  @media only screen and (max-width: 448px) {
    .mobile-login-form {
      width: 100vw;
      margin: 0 0;
    }
  }

	.mobile-login-help-text {
		font-size: 14px;
		color: white;
		line-height: 1.6;
	}

	.mobile-login-background {
		width: 100%;
		height: 100%;
		background: var(--secondary-color);
		position: absolute;
		top: -5em;
		left: 0;
		right: 0;
		margin: auto;
	}

	.mobile-login-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		text-align: center;
		padding: 0em 2em 0em 2em;
	}

	.mobile-login-header {
	    width: 100%;
	    height: 110px;
	    margin: 1em auto;
	  }

	.mobile-login-email {
        color: black;
	    width: 100%;
	    padding: 10px 15px;
	    margin: 2em auto;
	    border-radius: 5px;
        font-size: 18px;
	    border: none;
	    background: white;
	    font-family: 'Poppins', sans-serif; 
        outline: none;
	}

	.mobile-login-email::placeholder {
        color: black;
	    font-size: 14px;
	}

    .mobile-login-email:focus {
        box-shadow: 0 0 0 2pt var(--secondary-color);
	}

	.mobile-login-inputs {
		margin-top: -4em;
	}

	.mobile-login-footer {
		position: absolute;
		bottom: 46%;
		left: 0;
		right: 0;
		padding: .5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	}

  .mobile-login-dentalplus {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: .5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	}

  @media only screen and (max-height: 840px) {
    .mobile-login-footer {
      bottom: 44%;
    }
  }

  @media only screen and (max-height: 820px) {
    .mobile-login-footer {
      bottom: 42%;
    }
  }

  @media only screen and (max-height: 820px) {
    .mobile-login-footer {
      bottom: 40%;
    }
  }

  @media only screen and (max-height: 800px) {
    .mobile-login-footer {
      bottom: 38%;
    }
  }

  @media only screen and (max-height: 780px) {
    .mobile-login-footer {
      bottom: 36%;
    }
  }

  @media only screen and (max-height: 760px) {
    .mobile-login-footer {
      bottom: 34%;
    }
  }

  @media only screen and (max-height: 740px) {
    .mobile-login-footer {
      bottom: 32%;
    }
  }

  @media only screen and (max-height: 720px) {
    .mobile-login-footer {
      bottom: 30%;
    }
  }

  @media only screen and (max-height: 700px) {
    .mobile-login-footer {
      bottom: 28%;
    }
  }

  @media only screen and (max-height: 680px) {
    .mobile-login-footer {
      bottom: 26%;
    }
  }

  @media only screen and (max-height: 660px) {
    .mobile-login-footer {
      bottom: 24%;
    }
  }

  @media only screen and (max-height: 640px) {
    .mobile-login-footer {
      bottom: 22%;
    }
  }

  @media only screen and (max-height: 620px) {
    .mobile-login-footer {
      bottom: 20%;
    }
  }

  @media only screen and (max-height: 600px) {
    .mobile-login-footer {
      bottom: 18%;
    }
  }

  @media only screen and (max-height: 580px) {
    .mobile-login-footer {
      bottom: 16%;
    }
  }

  @media only screen and (max-height: 560px) {
    .mobile-login-footer {
      bottom: 14%;
    }
  }

  @media only screen and (max-height: 540px) {
    .mobile-login-footer {
      bottom: 12%;
    }
  }

  @media only screen and (max-height: 520px) {
    .mobile-login-footer {
      bottom: 10%;
    }
  }

  @media only screen and (max-height: 500px) {
    .mobile-login-footer {
      bottom: 8%;
    }
  }
  
  @media only screen and (max-height: 480px) {
    .mobile-login-footer {
      bottom: 6%;
    }
  }

  @media only screen and (max-height: 460px) {
    .mobile-login-footer {
      bottom: 4%;
    }
  }

  @media only screen and (max-height: 440px) {
    .mobile-login-footer {
      bottom: 2%;
    }
  }

	.mobile-login-signin-button {
		  width: 80%;
	    padding: 13px 15px;
	    border-radius: 5px;
	    border: 1px solid white;
	    background: var(--primary-color);
      font-size: 16px;
	    font-family: 'Poppins', sans-serif;
	    color: #fff;
	}

  .mobile-login-signin-button:hover {
		cursor: pointer;
    border-color: var(--primary-hover-color);
    background: var(--primary-hover-color);
	}

    .mobile-login-signup-button {
		width: 80%;
	    padding: 10px 15px;
	    border-radius: 5px;
	    border: none;
	    background: var(--primary-color);
        font-size: 16px;
	    font-family: 'Poppins', sans-serif;
	    outline: none;
	    color: #fff;
	}

    .mobile-login-signup-button:hover {
		cursor: pointer;
	    background: var(--primary-hover-color);
	}

    .dentalplus-website {
		color: var(--primary-color);
	}